<template>
  <footer class="section footer footer-styles has-text-white">
    <div class="container">
      <div class="content-wrapper">
        <div class="columns">
          <div class="column">
            <div>
              <p class="has-text-weight-bold py-4">
                About
              </p>
            </div>

            <span>
              <div>
                <ul role="menu">
                  <li class="pb-3">
                    <a
                      class="has-text-white"
                      href="https://percayso-vehicle-intelligence.co.uk"
                    >Percayso Vehicle Intelligence
                    </a>
                  </li>
                </ul>
              </div>
            </span>
          </div>

          <div class="column">
            <div>
              <p class="has-text-weight-bold py-4">
                Products
              </p>
            </div>
            <span>
              <div>
                <ul role="menu">
                  <li class="pb-3">
                    <a
                      class="has-text-white"
                      href="https://percayso-vehicle-intelligence.co.uk/product/companion"
                    >
                      Companion
                    </a>
                  </li>
                  <li class="pb-3">
                    <a
                      class="has-text-white"
                      href="https://percayso-vehicle-intelligence.co.uk/product/multi"
                    >
                      Multi
                    </a>
                  </li>
                  <li class="pb-3">
                    <a
                      class="has-text-white"
                      href="https://percayso-vehicle-intelligence.co.uk/product/api-data-solutions"
                    >
                      API &amp; Data Solutions
                    </a>
                  </li>
                </ul>
              </div>
            </span>
          </div>
          <div class="column">
            <div>
              <p class="has-text-weight-bold py-4">
                Solutions
              </p>
            </div>

            <span>
              <div>
                <ul role="menu">
                  <li class="pb-3">
                    <a
                      class="has-text-white"
                      href="https://percayso-vehicle-intelligence.co.uk/solutions/insurance"
                    >Insurance
                    </a>
                  </li>
                  <li class="pb-3">
                    <a
                      class="has-text-white"
                      href="https://percayso-vehicle-intelligence.co.uk/solutions/lenders"
                    >Lenders
                    </a>
                  </li>
                  <li class="pb-3">
                    <a
                      class="has-text-white"
                      href="https://percayso-vehicle-intelligence.co.uk/solutions/vehicle-manufacturers"
                    >Vehicle Manufacturers
                    </a>
                  </li>
                  <li class="pb-3">
                    <a
                      class="has-text-white"
                      href="https://percayso-vehicle-intelligence.co.uk/partnerships-and-resellers"
                    >Partners</a>
                  </li>
                </ul>
              </div></span>
          </div>
          <div class="column">
            <div>
              <p class="has-text-weight-bold py-4">
                Resources
              </p>
            </div>

            <span>
              <div>
                <ul role="menu">
                  <li class="pb-3">
                    <a
                      class="has-text-white"
                      href="https://percayso-vehicle-intelligence.co.uk/resources"
                    >
                      All Resources
                    </a>
                  </li>
                </ul>
              </div>
            </span>
          </div>

          <div class="column">
            <div>
              <p class="has-text-weight-bold py-4">
                Contact us
              </p>
              <p>
                Hine House, <br>25 Regent Street, <br>Nottingham <br>NG1
                5BS
              </p>
            </div>
          </div>
        </div>
        <div class="columns is-align-items-flex-end">
          <div :class="{ 'p-3': $mq === 'mobile' }">
            <a
              class="has-text-white"
              href="https://percayso-vehicle-intelligence.co.uk"
            >
              <img
                :src="require('core/img/logos/percayso-logo.svg')"
                alt="footer-percayso"
                loading="lazy"
                style="max-width: 120px; height: auto"
              >
            </a>
          </div>
          <div class="column">
            <ul class="columns is-justify-content-center">
              <li :class="[$mq === 'mobile' ? 'column' : 'pr-4']">
                <a
                  class="has-text-white"
                  href="https://www.percayso-inform.com/modern-slavery-statement"
                  target="_blank"
                  rel="noopener"
                >Modern Slavery Statement
                </a>
              </li>
              <li :class="[$mq === 'mobile' ? 'column' : 'pr-4']">
                <a
                  class="has-text-white"
                  href="https://www.percayso-inform.com"
                  target="_blank"
                  rel="noopener"
                >Careers
                </a>
              </li>
              <li :class="[$mq === 'mobile' ? 'column' : 'pr-4']">
                <a
                  class="has-text-white"
                  href="https://www.percayso-inform.com/privacy-policy"
                  target="_self"
                >
                  Privacy Policy</a>
              </li>
              <li :class="[$mq === 'mobile' ? 'column' : 'pr-4']">
                <a
                  class="has-text-white"
                  href="https://www.percayso-inform.com/user-terms"
                  target="_self"
                >
                  User Terms
                </a>
              </li>
              <li :class="{ column: $mq === 'mobile' }">
                <a
                  class="has-text-white"
                  href="https://www.percayso-inform.com/cookie-policy"
                  target="_self"
                >
                  Cookie policy
                </a>
              </li>
            </ul>
          </div>
          <div :class="{ 'p-3': $mq === 'mobile' }">
            <h3 class="has-text-weight-bold py-4">
              Follow us
            </h3>
            <div class="columns">
              <span class="column">
                <a
                  class="has-text-white"
                  href="https://www.linkedin.com/company/percaysoinformvehicleintelligence"
                >
                  <i class="fab fa-linkedin is-size-4" />
                </a>
              </span>
              <span class="column">
                <a
                  class="has-text-white"
                  href="https://twitter.com/PercaysoInform"
                >
                  <i class="fab fa-twitter is-size-4" />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    currentYear: () => new Date().getFullYear()
  },
  methods: {
    track(linkType, linkName) {
      const link = linkName.toLowerCase().split(' ').join('_')
      this.$mxp.track(`app_footer_${linkType}_${link}`)
    }
  }
}
</script>

<style lang="sass" scoped>
.footer
  line-height: 1.3em
  border-top: 10px solid $footer-top-border-color
  @media screen and (max-width: 768px)
    padding: 1.5em
</style>
